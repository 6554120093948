export function calculateOpenTime(hour) {
  let actualDate = new Date().toLocaleDateString().split('/');
  actualDate[0] = actualDate[0] < 10 ? `0${actualDate[0]}` : actualDate[0];
  actualDate[1] = actualDate[1] < 10 ? `0${actualDate[1]}` : actualDate[1];
  actualDate = `${actualDate[2]}-${actualDate[1]}-${actualDate[0]}`;
  if (!hour || hour === actualDate) {
    let firstHour = new Date().toLocaleTimeString();
    firstHour = firstHour.split(':');
    firstHour.pop();
    if (Number(firstHour[0]) < 21) {
      firstHour[0] = Number(firstHour[0]) + 3;
      firstHour[0] = firstHour[0] < 10 ? `0${firstHour[0]}`.replace('-', '') : firstHour[0];
      firstHour[1] = Number(firstHour[1]) <= 30 ? '00' : '30';
      firstHour = firstHour.join(':');
      return firstHour;
    }
    return null;
  }
  return '00:00';
}

export function calculateTime(hour) {
  if (hour) {
    let dateTime = hour.split(':');
    dateTime.pop();
    dateTime[1] = Number(dateTime[1]) >= 30 ? '30' : '00';
    dateTime[0] = dateTime[0] < 10 ? `0${dateTime[0]}`.replace('-', '') : dateTime[0];
    dateTime = dateTime.join(':');
    return dateTime;
  }
  return '00:00';
}

export function calculateCloseTime(hour) {
  if (hour) {
    let secondHour = hour.split(':');
    if (Number(secondHour[0]) < 22) {
      secondHour[1] = Number(secondHour[1]) >= 30 ? '30' : '00';
      secondHour[0] = Number(secondHour[0]) + 2;
      secondHour[0] = secondHour[0] < 10 ? `0${secondHour[0]}`.replace('-', '') : secondHour[0];
      secondHour = secondHour.join(':');
      return secondHour;
    }
    return null;
  }
  return '00:00';
}

export function validateState(field) {
  if (!field) {
    return false;
  }
  return true;
}
