<template>
  <div class="items-table">
    <b-alert
      class="items-table__info-section"
      show
      variant="danger"
      v-if="hazmatCheck && typeTable === 'LineItems' && validateChecked"
      >At least one item must have hazmat checked</b-alert
    >
    <h6 class="items-table__title">{{ titleTable }}</h6>
    <b-table
      :key="renderTable"
      class="items-table__table"
      stacked="md"
      hover
      :items="itemsTable"
      :fields="itemsfields"
      outlined
      responsive
    >
      <template #head(commodity)="data">
        <b-icon
          v-b-tooltip.hover.v-info.left.html="`${tooltipMessage}`"
          class="items-table__table--info-icon"
          :variant="validateValueCommodity ? '' : 'danger'"
          icon="info-circle-fill"
        ></b-icon
        ><span> {{ data.label }}</span>
      </template>
      <template #head(packaging_unit_count)="data">
        <b-icon
          v-if="!validatePackagingFields"
          v-b-tooltip.hover.v-info.left.html="
            `If you enter a Unit Count value, it must be <b>greater than 0</b>.`
          "
          class="items-table__table--info-icon"
          :variant="validatePackagingFields ? '' : 'danger'"
          icon="info-circle-fill"
        ></b-icon
        ><span> {{ data.label }}</span>
      </template>
      <template v-slot:cell(line)="row">
        <span>Item {{ row.index + 1 }}</span>
      </template>
      <template v-slot:cell(HU)="row">
        <span> {{ itemsTable[row.index].hu_count }}</span>
      </template>
      <template v-slot:cell(NMFC)="row">
        <span> {{ itemsTable[row.index].nmfc }}</span>
      </template>
      <template v-slot:cell(commodity)="row">
        <b-form-input
          class="items-table__input"
          v-model="description[row.index]"
          @change="setDescription(row.index)"
          placeholder="Description"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="text"
          autocomplete="off"
          :state="validateCommodity[row.index]"
        ></b-form-input>
      </template>
      <template v-slot:cell(packaging_unit_count)="row">
        <b-form-input
          class="items-table__input"
          v-model="number[row.index]"
          @change="setNumber(row.index)"
          placeholder="Unit count"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="number"
          autocomplete="off"
          :state="validatePackagingUnitCount[row.index]"
        ></b-form-input>
      </template>
      <template v-slot:cell(packaging_units)="row">
        <el-select
          :style="{
            border: `1px solid ${validatePackagingUnits[row.index]} !important`,
            'border-radius': '10px',
          }"
          id="select-packaging-units"
          v-if="showPicklistsHazmat"
          v-model="packagingUnits[row.index]"
          @change="selectUnit(row.index)"
          placeholder="Packaging units"
          clearable
          filterable
        >
          <el-option
            v-for="item in showPicklistsHazmat.packaging_units"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template v-slot:cell(hazardous_materials)="row">
        <div class="items-table__hazmat-row">
          <b-form-checkbox
            :key="renderCheckbox"
            :id="'checkbox-' + row.index + 1"
            v-model="isChecked[row.index]"
            @change="changeCheckHazmat(row.index)"
          >
          </b-form-checkbox>
          <span v-if="isChecked[row.index]"
            ><i @click="editHazmat(row.index)" class="ion ion-create-outline"></i
          ></span>
        </div>
      </template>
      <template v-slot:cell(carrier_service)="row">
        <span> {{ itemsTable[row.index].name }}</span>
      </template>
      <template v-slot:cell(transit_days)="row">
        <span> {{ itemsTable[row.index].transit_time }} Bus. Days</span>
      </template>
      <template v-slot:cell(customer_quote_total)="row">
        <span>USD {{ itemsTable[row.index].total_price }}</span>
      </template>
      <template v-slot:cell(pick_up_performance_MO)="row">
        <span> {{ itemsTable[row.index].pickup_performance }}</span>
      </template>
      <template v-slot:cell(delivery_performance_MO)="row">
        <span> {{ itemsTable[row.index].delivery_performance }}</span>
      </template>
    </b-table>
    <HazmatInfoModal
      @validateHazmat="validateHazmat"
      :modalNumber="modalNumber"
      :isChecked="isChecked"
      :editHazmatInfo="editHazmatInfo"
    ></HazmatInfoModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HazmatInfoModal from './HazmatInfoModal.vue';

export default {
  name: 'ItemsTable',
  props: {
    shipmentInfo: Object,
    typeTable: String,
    hazmatCheck: Boolean,
  },
  components: {
    HazmatInfoModal,
  },
  data() {
    return {
      lineItemsfields: [
        'line',
        'HU',
        'dimensions',
        'weight',
        'NMFC',
        'commodity',
        'packaging_unit_count',
        'packaging_units',
      ],
      carrierInformationFields: [
        'carrier_service',
        'direct_interline',
        'transit_days',
        'service_class',
        'expiration_date',
        'carrier_liability_limit',
        'customer_quote_total',
        'pick_up_performance_MO',
        'delivery_performance_MO',
      ],
      itemsCarrier: [],
      itemsfields: null,
      itemsTable: null,
      titleTable: null,
      isChecked: [],
      modalNumber: null,
      renderCheckbox: 0,
      editHazmatInfo: false,
      validateChecked: true,
      number: [],
      description: [],
      packagingUnits: [],
      lineItemsArray: [],
      validatePackagingUnits: [],
      validatePackagingUnitCount: [],
      validateCommodity: [],
      validateValueCommodity: false,
      renderTable: 0,
      validatePackagingFields: false,
      tooltipMessage:
        'Please specify the actual commodity <br>(descriptions such as "<u>Commodity</u>", "<u>General Cargo</u>", "<u>HHGG</u>", "<u>HHG</u>" or "<u>FAK</u>" <b>are not allowed</b>).',
    };
  },
  computed: {
    ...mapGetters('booking', ['showHazmatInfo']),
    ...mapGetters('lineItems', ['showPicklistsHazmat']),
  },
  watch: {
    showHazmatInfo() {
      const hazmatArray = [];
      if (this.showHazmatInfo) {
        if (this.typeTable === 'LineItems') {
          this.showHazmatInfo.forEach((element, index) => {
            if (element) {
              const salesforceId = this.shipmentInfo.line_items[index].id_salesforce;
              this.lineItemsArray[index].hazmat_info = { ...element.hazmat_info };
              this.lineItemsArray[index].id_salesforce = salesforceId;
              hazmatArray.push(element);
            } else {
              delete this.lineItemsArray[index].hazmat_info;
            }
            this.constructObjectItem();
          });
          this.validateChecked = hazmatArray.length === 0;
          this.$emit('validateHazmat', !this.validateChecked);
        }
      }
    },
  },
  created() {
    if (this.shipmentInfo) {
      this.constructTable();
    }
  },
  methods: {
    constructTable() {
      if (this.typeTable === 'LineItems') {
        this.itemsTable = this.shipmentInfo.line_items;
        this.constructColumn();
        this.titleTable = 'Line Item Details';
      } else {
        this.itemsCarrier.push(this.shipmentInfo.carrier);
        this.itemsTable = this.itemsCarrier;
        this.itemsfields = this.carrierInformationFields;
        this.titleTable = 'Carrier Information';
      }
    },
    changeCheckHazmat(index) {
      this.modalNumber = index;
      if (!this.isChecked[index]) {
        const hazmatInfo = {};
        hazmatInfo.info = null;
        hazmatInfo.modalIndex = index;
        this.$store.commit('booking/setHazmatInfo', hazmatInfo);
      }
    },
    constructColumn() {
      if (this.hazmatCheck) {
        this.lineItemsfields.push('hazardous_materials');
        this.shipmentInfo.line_items.forEach((lineItem, index) => {
          this.isChecked[index] = false;
        });
      }
      this.itemsfields = this.lineItemsfields;
      this.shipmentInfo.line_items.forEach((lineItem, index) => {
        this.lineItemsArray[index] = {};
        this.validatePackagingUnits[index] = this.$route.name === 'BookingMagaya' ? '#757687' : '#00b9cc';
        this.description[index] = this.shipmentInfo.line_items[index].commodity;
        this.setDescription(index);
      });
    },
    validateHazmat(value) {
      this.isChecked[this.modalNumber] = value;
      this.renderCheckbox += 1;
    },
    editHazmat(index) {
      this.modalNumber = index;
      this.editHazmatInfo = !this.editHazmatInfo;
    },
    selectUnit(index) {
      if (this.packagingUnits[index]) {
        const salesforceId = this.shipmentInfo.line_items[index].id_salesforce;
        this.lineItemsArray[index].packaging_units = this.packagingUnits[index];
        this.lineItemsArray[index].id_salesforce = salesforceId;
      } else {
        delete this.lineItemsArray[index].packaging_units;
      }
      this.constructObjectItem();
    },
    setNumber(index) {
      if (this.number[index] && this.number[index] > 0) {
        const salesforceId = this.shipmentInfo.line_items[index].id_salesforce;
        this.lineItemsArray[index].packaging_unit_count = this.number[index];
        this.lineItemsArray[index].id_salesforce = salesforceId;
      } else {
        delete this.lineItemsArray[index].packaging_unit_count;
      }
      this.constructObjectItem();
    },
    setDescription(index) {
      const invalidValuesCommodity = ['general cargo', 'fak', 'hhgg', 'hhg', 'commodity'];
      this.validateCommodity[index] = !invalidValuesCommodity.includes(
        this.description[index].toLowerCase(),
      );
      this.validateCommodity[index] = !this.description[index]
        ? null
        : this.validateCommodity[index];
      this.validateValueCommodity = !this.validateCommodity.includes(false);
      this.$emit(
        'validatePackagingFields',
        this.validatePackagingFields && this.validateValueCommodity,
      );
      if (
        this.description[index]
        && (this.validateCommodity[index] === null || this.validateCommodity[index])
      ) {
        const salesforceId = this.shipmentInfo.line_items[index].id_salesforce;
        this.lineItemsArray[index].description = this.description[index];
        this.lineItemsArray[index].id_salesforce = salesforceId;
      } else {
        delete this.lineItemsArray[index].description;
      }
      this.constructObjectItem();
    },
    constructObjectItem() {
      const lineItemArray = [];
      this.lineItemsArray.forEach((item, index) => {
        if (
          !item.hazmat_info
          && !item.packaging_units
          && !item.packaging_unit_count
          && !item.description
        ) {
          delete this.lineItemsArray[index].id_salesforce;
        }
        this.validateUnitFields(item, index);
        this.renderTable += 1;
        if (JSON.stringify(this.lineItemsArray[index]) !== '{}') {
          lineItemArray.push(item);
        }
      });
      this.validatePackagingFields = this.validateStates();
      this.$emit(
        'validatePackagingFields',
        this.validatePackagingFields && this.validateValueCommodity,
      );
      const hazmatInfo = {};
      hazmatInfo.propName = 'line_items_hazmat';
      hazmatInfo.info = {
        line_item_hazmat: [...lineItemArray],
      };
      this.$store.commit('booking/setSendBooking', hazmatInfo);
    },
    validateUnitFields(item, index) {
      if (!item.packaging_units && item.packaging_unit_count && this.number[index] > 0) {
        this.validatePackagingUnits[index] = 'red';
        this.validatePackagingUnitCount[index] = true;
      } else if (!item.packaging_units && this.number[index] < 1 && this.number[index]) {
        this.validatePackagingUnits[index] = this.$route.name === 'BookingMagaya' ? '#757687' : '#00b9cc';
        this.validatePackagingUnitCount[index] = false;
      } else if (item.packaging_units && item.packaging_unit_count && this.number[index] > 0) {
        this.validatePackagingUnits[index] = 'green';
        this.validatePackagingUnitCount[index] = true;
      } else if (item.packaging_units && (this.number[index] < 1 || !item.packaging_unit_count)) {
        this.validatePackagingUnits[index] = 'green';
        this.validatePackagingUnitCount[index] = false;
      } else {
        this.validatePackagingUnits[index] = this.$route.name === 'BookingMagaya' ? '#757687' : '#00b9cc';
        this.validatePackagingUnitCount[index] = null;
      }
    },
    validateStates() {
      let checkAllFields = false;
      for (let index = 0; index < this.validatePackagingUnits.length; index += 1) {
        if (
          (this.validatePackagingUnits[index] === 'green'
            && !this.validatePackagingUnitCount[index])
          || (this.validatePackagingUnits[index] === 'red'
            && this.validatePackagingUnitCount[index])
          || (this.validatePackagingUnits[index] === '#00b9cc'
            && this.validatePackagingUnitCount[index] === false)
        ) {
          checkAllFields = false;
          break;
        } else {
          checkAllFields = true;
        }
      }
      return checkAllFields;
    },
  },
};
</script>

<style scoped lang="scss">
.items-table {
  margin: 10px;
  width: 100%;
  &__title {
    text-align: start;
    font-weight: 700;
    color: $color-primary-company;
  }
  &__table {
    border-radius: 5px 5px 0 0;
    color: $color-background-dark;
    &--info-icon {
      cursor: pointer;
    }
  }
  &__input {
    border-radius: 10px;
    width: 100%;
    border: 1px solid $color-border;
    color: $color-primary-company;
    text-align: center;
  }
  &__hazmat-row {
    display: flex;
    align-items: center;
    justify-content: center;
    & span {
      color: $color-border;
      cursor: pointer;
      font-size: 19px;
    }
  }
}

:deep(.border) {
  border: none !important;
}

:deep(.table) {
  color: $color-primary-company;
}

:deep(.table thead th) {
  border-bottom: none !important;
}

:deep(.table th) {
  border-bottom: none !important;
  border-top: none !important;
}

:deep(.table td) {
  border-bottom: none !important;
  border-top: none !important;
}

:deep(thead) {
  color: $color-primary-company;
  border: 2px solid $color-border;
  margin-bottom: 3px;
  font-weight: 700;
}
:deep(th) {
  border-right: 2px solid $color-border;
}
:deep(tbody tr) {
  border-bottom: 2px solid $color-border;
}
:deep(.custom-control-input:checked ~ .custom-control-label::before) {
  border-color: $color-border !important;
  background-color: $color-border !important;
}
:deep(.custom-checkbox .custom-control-label::before) {
  border-color: $color-border !important;
}

:deep(.el-input__inner) {
  border-radius: 10px;
  border: none;
  color: $color-primary-company !important;
}
:deep(.el-select .el-input__inner:focus) {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
:deep(.el-select .el-input .el-select__caret) {
  color: $color-background-dark;
}
</style>
