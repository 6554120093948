<template>
  <div class="shipping-card" :id="`${titleCard.toLowerCase()}-shipping-card`">
    <article>
      <h3 class="shipping-card__title">{{ titleCard }}</h3>
      <h4 class="shipping-card__subtitle">{{ subtitleCard }} Location</h4>
      <div class="shipping-card__main-location-option">
        <!-- <DropdownLocation
          :titleCard="titleCard"
          :dropdownData="locationsData"
        ></DropdownLocation> -->
        <b-button
          @click="addLocationModal()"
          class="shipping-card__main-location-option--button"
          variant="outline-secondary"
          :id="`${titleCard.toLowerCase()}-shipping-card-button`"
          :style="{ border: '1px solid ' + colorButton }"
        >
          <div>
            <b-icon
              class="location-item shipping-card__main-location-option--plus-icon"
              :icon="locationName === 'Add location' ? 'plus-lg' : 'book-half'"
              aria-hidden="true"
            ></b-icon>
            {{ locationName }}
          </div>
          <b-icon
            class="location-item shipping-card__main-location-option--validate-icon"
            v-if="buttonIcon"
            :icon="buttonIcon"
            :style="{ color: colorButton }"
            scale="1"
          ></b-icon
        ></b-button>
      </div>
      <ul class="shipping-card__main-information">
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="geo-alt"></b-icon>
          {{ originShippingCard.street }} {{ originShippingCard.city }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="person"></b-icon>
          {{ originShippingCard.contactName }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="envelope"></b-icon>
          {{ originShippingCard.contactMail }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="telephone"></b-icon>
          {{ originShippingCard.contactPhone }}
        </li>
      </ul>
    </article>
    <article>
      <b-form-datepicker
        placeholder="Shipping date"
        :id="`datepicker-sm-${titleCard.toLowerCase()}`"
        v-model="originData.expected_date"
        class="mb-2 shipping-card__form-input"
        locale="en"
        :min="new Date()"
        :state="validateStates.state1"
        :date-disabled-fn="dateDisabled"
      ></b-form-datepicker>
      <h6 class="shipping-card__form-time-subtitle">Shipping hours</h6>
      <div class="shipping-card__form-time">
        <el-time-select
          :id="`timepicker-placeholder-${titleCard.toLowerCase()}-first-hour`"
          v-model="hours.firstHourShipping"
          class="shipping-card__form-input shipping-card__form-input--time"
          placeholder="Open"
          :style="{ border: '1px solid ' + validateStates.state2 }"
          :picker-options="{
            start: this.openTime,
            minTime: this.minOpenTime,
            step: '00:30',
            end: '23:30',
          }"
        >
        </el-time-select>
        <el-time-select
          :id="`timepicker-placeholder-${titleCard.toLowerCase()}-second-hour`"
          v-model="hours.secondHourShipping"
          class="shipping-card__form-input shipping-card__form-input--time"
          placeholder="Close"
          :style="{ border: '1px solid ' + validateStates.state3 }"
          :picker-options="{
            start: closeTime,
            step: '00:30',
            end: '23:30',
          }"
          :disabled="!hours.firstHourShipping"
        >
        </el-time-select>
      </div>
      <b-form-input
        class="shipping-card__form-input"
        v-model="originData.references"
        placeholder="References required for pickup (SKU, PO, Order Number…)"
        id="input-small"
        type="text"
      ></b-form-input>
      <b-form-textarea
        class="shipping-card__form-input"
        v-model="originData.instructions"
        id="textarea-small"
        size="sm"
        placeholder="Instructions"
      ></b-form-textarea>
    </article>
    <AccountCreationModal
    titleCard="Origin"
    :cityOptions="cityOptions"
    @getOriginCityByZip="getOriginCityByZip"
    ></AccountCreationModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/* import DropdownLocation from './DropdownLocation.vue'; */
import AccountCreationModal from './AccountCreationModal.vue';
import {
  calculateCloseTime, calculateTime,
} from '../../../mixins/shippingCard/shippingInformation';

export default {
  name: 'OriginShippingCard',
  props: {
    titleCard: String,
    subtitleCard: String,
    shipmentInfo: Object,
    checkInfoCards: Boolean,
  },
  components: {
    /* DropdownLocation, */
    AccountCreationModal,
  },
  data() {
    return {
      haulInfo: null,
      originShippingCard: {
        city: null,
        street: null,
        contactName: null,
        contactMail: null,
        contactPhone: null,
      },
      locationsData: null,
      locationName: 'Add location',
      originData: {
        shipping_receiving_hours: null,
        expected_date: null,
        references: null,
        instructions: null,
        is_dropoff: false,
        is_pickup: true,
      },
      hours: {
        firstHourShipping: null,
        secondHourShipping: null,
      },
      infoCheking: false,
      checkSelectedAccount: false,
      checkSelectedContact: false,
      validateStates: {
        state1: null,
        state2: '#d2d7dc',
        state3: '#d2d7dc',
        state4: null,
      },
      colorButton: null,
      buttonIcon: null,
      openTime: null,
      minOpenTime: null,
      closeTime: null,
      timeZones: null,
      cityOptions: null,
      cityByZipCode: null,
    };
  },
  computed: {
    ...mapGetters('salesforceAccounts', ['getAllAccounts']),
    ...mapGetters('salesforceAccounts', ['getOriginSelectedAccount']),
    ...mapGetters('salesforceContacts', ['getOriginSelectedContact']),
  },
  watch: {
    originData: {
      handler(value) {
        const pickUpInfo = {};
        this.setExpectedDate(value.expected_date);
        this.calculateOpenTime(value.expected_date);
        pickUpInfo.info = { ...value };
        pickUpInfo.propName = 'shipper';
        this.$store.commit('booking/setSendBooking', pickUpInfo);
      },
      deep: true,
    },
    hours: {
      handler(value) {
        if (!value.firstHourShipping) {
          this.hours.secondHourShipping = null;
        }
        this.closeTime = calculateCloseTime(this.hours.firstHourShipping);
        if (value.firstHourShipping && value.secondHourShipping) {
          const firstHourFormat = value.firstHourShipping.split(':');
          const secondHourFormat = value.secondHourShipping.split(':');
          this.originData.shipping_receiving_hours = `${firstHourFormat[0]}:${firstHourFormat[1]}-${secondHourFormat[0]}:${secondHourFormat[1]}`;
        }
      },
      deep: true,
    },
    getAllAccounts() {
      const options = [...this.getAllAccounts];
      for (let i = 0; i < options.length; i += 1) {
        options[
          i
        ].name_complete = `${options[i].name}, ${options[i].city} ${options[i].state_code} ${options[i].id}`;
      }
      options.unshift({
        label: 'Create a new location',
        name: 'Create a new location',
        name_complete: 'Create a new location',
      });
      this.locationsData = [...options];
    },
    getOriginSelectedAccount() {
      this.locationName = `${this.getOriginSelectedAccount.name}, ${this.getOriginSelectedAccount.postal_code}`;
      this.originShippingCard.street = `${this.getOriginSelectedAccount.street},`;
      if (this.getOriginSelectedAccount.id_salesforce) {
        this.checkSelectedAccount = true;
      }
      const accountInfo = {};
      accountInfo.propName = 'shipper_account_id';
      accountInfo.info = this.getOriginSelectedAccount.id_salesforce;
      this.$store.commit('booking/setSendBooking', accountInfo);
    },
    getOriginSelectedContact(value) {
      if (this.getOriginSelectedContact) {
        this.originShippingCard.contactName = `${this.getOriginSelectedContact.first_name} ${this.getOriginSelectedContact.last_name}`;
        this.originShippingCard.contactMail = this.getOriginSelectedContact.email;
        this.originShippingCard.contactPhone = this.getOriginSelectedContact.phone;
        if (value.id_salesforce) {
          this.checkSelectedContact = true;
        }
        const contactInfo = {};
        contactInfo.propName = 'shipper_contact_id';
        contactInfo.info = value.id_salesforce;
        this.$store.commit('booking/setSendBooking', contactInfo);
      }
    },
    checkInfoCards() {
      this.infoCheking = this.checkForm();
      this.$emit('validateOriginCard', this.infoCheking);
    },
  },
  async created() {
    if (this.shipmentInfo) {
      this.haulInfo = this.shipmentInfo.haul;
      this.completeInfo();
      await this.getCityByZipCode();
    }
    this.calculateOpenTime(this.hours.firstHourShipping);
    this.closeTime = calculateCloseTime(this.originData.expected_date);
  },
  methods: {
    completeInfo() {
      this.originShippingCard.city = this.titleCard === 'Origin'
        ? `${this.cityByZipCode || this.haulInfo.city_from}, ${this.haulInfo.state_from}`
        : `${this.cityByZipCode || this.haulInfo.city_to}, ${this.haulInfo.state_to}`;
    },
    addLocationModal() {
      this.$bvModal.show(`${this.titleCard.toLowerCase()}-my-location-modal`);
    },
    setExpectedDate(value) {
      if (value) {
        this.$store.commit('booking/setOriginExpectedDate', value);
      }
    },
    calculateOpenTime(date) {
      if (this.timeZones && (!date || date === this.timeZones.date)) {
        this.openTime = calculateTime(this.timeZones.hour);
        this.minOpenTime = this.openTime;
      } else {
        this.openTime = '00:00';
        this.minOpenTime = null;
      }
    },
    checkForm() {
      if (!this.originData.expected_date) {
        this.validateStates.state1 = false;
      } else {
        this.validateStates.state1 = true;
      }
      if (!this.hours.firstHourShipping) {
        this.validateStates.state2 = '#dc3545';
      } else {
        this.validateStates.state2 = '#28a745';
      }
      if (!this.hours.secondHourShipping) {
        this.validateStates.state3 = '#dc3545';
      } else {
        this.validateStates.state3 = '#28a745';
      }
      if (!this.checkSelectedAccount || !this.checkSelectedContact) {
        this.validateStates.state4 = false;
      } else {
        this.validateStates.state4 = true;
      }
      this.changeStyle();
      if (
        !this.validateStates.state1
        || this.validateStates.state2 === '#dc3545'
        || this.validateStates.state3 === '#dc3545'
        || !this.validateStates.state4
      ) {
        return false;
      }
      return true;
    },
    changeStyle() {
      if (!this.validateStates.state4) {
        this.buttonCheckIcon = false;
        this.buttonIcon = 'x-circle';
        this.colorButton = '#dc3545';
      } else {
        this.buttonCheckIcon = true;
        this.buttonIcon = 'check2-circle';
        this.colorButton = '#28a745';
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6;
    },
    async getCityByZipCode() {
      const params = {
        zip_code: this.haulInfo.zip_from,
        'api-key': this.shipmentInfo.api_key,
      };
      const citiesByZipResponse = await this.$store.dispatch('informationByZone/getCitiesByZip', params);
      this.cityOptions = citiesByZipResponse?.data;
    },
    async getInformationByZone() {
      const params = {
        zip_code: this.haulInfo.zip_from,
        'api-key': this.shipmentInfo.api_key,
        city: this.cityByZipCode || this.haulInfo.city_from,
      };
      const timeZonesResponse = await this.$store.dispatch('informationByZone/getTimeZones', params);
      this.timeZones = timeZonesResponse?.data;
    },
    getOriginCityByZip(value) {
      this.cityByZipCode = value;
      this.completeInfo();
      this.getInformationByZone();
    },
  },
};
</script>

<style scoped lang="scss">
.shipping-card {
  @include shippingCard;
}

:deep(.b-form-btn-label-control.form-control > .btn) {
  color: $color-secondary-button;
}

:deep(.form-control:focus) {
  border-color: $color-secondary-button;
}
:deep(.el-input__inner) {
  border-radius: 10px;
  border: none;
  height: 35px;
  width: 100%;
  color: $color-background-dark;
  font-size: 1rem;
  background-color: $color-white;
  line-height: unset;
  text-align: center;
}

:deep(.el-input__icon) {
  color: $color-secondary-button;
}

@media (max-width: 650px) {
  .shipping-card {
    width: 80%;
  }
}
@media (max-width: 450px) {
  .shipping-card__form-time {
    flex-direction: column;
  }
  .shipping-card__form-input--time {
    width: 100%;
  }
}
</style>
