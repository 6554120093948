<template>
  <b-modal
    ref="my-hazmat-materials-modal"
    class="hazmat-materials-modal"
    ok-disabled
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <template #modal-header>
      <h5 class="hazmat-materials-modal__title">Hazmat Information - Item {{ modalNumber + 1 }}</h5>
      <b-button
        class="hazmat-materials-modal__close-button"
        size="sm"
        variant="secondary"
        @click="closeModal()"
      >
        ×
      </b-button>
    </template>
    <b-form
      v-if="showPicklistsHazmat"
      class="hazmat-materials-modal__form"
      @submit.stop.prevent="onSubmit"
    >
      <section class="hazmat-materials-modal__form-section">
        <b-form-group
          id="input-group-2"
          label="Hazmat number type"
          label-for="input-2"
          class="hazmat-materials-modal__form-section-subtitle"
        >
          <b-form-select
            id="input-2"
            v-model="hazmatForm.hazmat_number_type"
            class="hazmat-materials-modal__form-input"
            oninvalid="setCustomValidity('Please select one item from the list')"
            oninput="setCustomValidity('')"
            :state="validateStates['state2']"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select number type --</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="(option, index) in showPicklistsHazmat.hazmat_number_type"
              :key="index"
              :value="option.value"
              >{{ option.value }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-4"
          label="Hazmat number"
          label-for="input-2"
          class="hazmat-materials-modal__form-section-subtitle"
        >
          <b-form-input
            class="hazmat-materials-modal__form-input"
            v-model="hazmatForm.hazmat_number"
            placeholder="Hazmat number"
            id="input-small"
            type="number"
            autocomplete="off"
            oninvalid="setCustomValidity('Complete this field.')"
            oninput="setCustomValidity('')"
            :state="validateStates['state4']"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Hazmat class division"
          label-for="input-1"
          class="hazmat-materials-modal__form-section-subtitle"
        >
          <b-form-select
            id="input-1"
            v-model="hazmatForm.hazmat_class_division"
            class="hazmat-materials-modal__form-input"
            oninvalid="setCustomValidity('Please select one item from the list')"
            oninput="setCustomValidity('')"
            :state="validateStates['state1']"
            place
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select class division --</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="(option, index) in showPicklistsHazmat.hazmat_class_division"
              :key="index"
              :value="option.value"
              >{{ option.value }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label="Hazmat packaging group"
          label-for="input-3"
          class="hazmat-materials-modal__form-section-subtitle"
        >
          <b-form-select
            id="input-3"
            v-model="hazmatForm.hazmat_packaging_group"
            class="hazmat-materials-modal__form-input"
            oninvalid="setCustomValidity('Please select one item from the list')"
            oninput="setCustomValidity('')"
            :state="validateStates['state3']"
            required
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Please select packaging group --</b-form-select-option
              >
            </template>
            <b-form-select-option
              v-for="(option, index) in showPicklistsHazmat.hazmat_packaging_group"
              :key="index"
              :value="option.value"
              >{{ option.value }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </section>
      <b-button
        class="hazmat-materials-modal__form-button"
        type="submit"
        size="sm"
        @click="save()"
        :disabled="busy"
      >
        Save <b-spinner v-if="busy" small></b-spinner>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { validateState } from '../../../mixins/shippingCard/shippingInformation';

export default {
  name: 'HazmatInfoModal',
  props: {
    modalNumber: Number,
    isChecked: Array,
    editHazmatInfo: Boolean,
  },
  components: {},
  data() {
    return {
      hazmatForm: {
        hazardous_materials: true,
        hazmat_class_division: null,
        hazmat_number_type: null,
        hazmat_packaging_group: null,
        hazmat_number: null,
      },
      busy: false,
      validateStates: ['state1', 'state2', 'state3', 'state4'],
      infoCheking: false,
      itemHazmat: null,
    };
  },
  computed: {
    ...mapGetters('lineItems', ['showPicklistsHazmat']),
    ...mapGetters('booking', ['showBookingInfo']),
    ...mapGetters('booking', ['showHazmatInfo']),
  },
  watch: {
    isChecked() {
      setTimeout(() => {
        if (this.isChecked[this.modalNumber]) {
          this.refreshModal();
        }
      }, '200');
    },
    editHazmatInfo() {
      if (this.showHazmatInfo[this.modalNumber]) {
        this.itemHazmat = this.showHazmatInfo[this.modalNumber];
        const infoItemHazmat = this.itemHazmat.hazmat_info;
        this.hazmatForm.hazmat_class_division = infoItemHazmat.hazmat_class_division;
        this.hazmatForm.hazmat_number_type = infoItemHazmat.hazmat_number_type;
        this.hazmatForm.hazmat_packaging_group = infoItemHazmat.hazmat_packaging_group;
        this.hazmatForm.hazmat_number = infoItemHazmat.hazmat_number;
        this.infoCheking = this.checkForm();
        this.$refs['my-hazmat-materials-modal'].show();
      }
    },
  },
  created() {
    if (this.showBookingInfo) {
      const haulInfo = this.showBookingInfo.haul;
      this.shippingInfo = `${haulInfo.city_from}/${haulInfo.state_from}/${haulInfo.zip_from}`;
    }
  },
  methods: {
    save() {
      this.busy = true;
      this.infoCheking = this.checkForm();
      if (this.infoCheking) {
        const hazmatInfo = {};
        hazmatInfo.info = {
          hazmat_info: { ...this.hazmatForm },
        };
        hazmatInfo.modalIndex = this.modalNumber;
        this.$store.commit('booking/setHazmatInfo', hazmatInfo);
        this.busy = false;
        this.$refs['my-hazmat-materials-modal'].hide();
      }
      this.$emit('validateHazmat', this.infoCheking);
      this.busy = false;
    },
    onSubmit(event) {
      event.preventDefault();
    },
    checkForm() {
      this.validateColors();
      if (!this.hazmatForm.hazmat_number_type
        || !this.hazmatForm.hazmat_number
        || !this.hazmatForm.hazmat_class_division
        || !this.hazmatForm.hazmat_packaging_group) {
        return false;
      }
      return true;
    },
    validateColors() {
      this.validateStates.state2 = validateState(this.hazmatForm.hazmat_number_type);
      this.validateStates.state4 = validateState(this.hazmatForm.hazmat_number);
      this.validateStates.state1 = validateState(this.hazmatForm.hazmat_class_division);
      this.validateStates.state3 = validateState(this.hazmatForm.hazmat_packaging_group);
    },
    closeModal() {
      this.$refs['my-hazmat-materials-modal'].hide();
      this.$emit('validateHazmat', this.infoCheking);
    },
    refreshModal() {
      this.$refs['my-hazmat-materials-modal'].show();
      this.infoCheking = false;
      this.validateStates = ['state1', 'state2', 'state3', 'state4'];
      this.hazmatForm.hazmat_class_division = null;
      this.hazmatForm.hazmat_number_type = null;
      this.hazmatForm.hazmat_packaging_group = null;
      this.hazmatForm.hazmat_number = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.hazmat-materials-modal {
  border-radius: 10px;
  color: $color-primary-company;
  font-size: 22px;
  font-weight: 600;
  text-align: start;
  @include modalForm;
  &__title {
    color: $color-primary-company;
    font-size: 22px;
    font-weight: 600;
  }
  &__close-button {
    border: none;
    background: none;
    font-size: 1.5rem;
    float: right;
    color: #000;
    opacity: 0.5;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    &:hover {
      opacity: 1 !important;
      border-color: transparent !important;
    }
    &:active {
      color: #000 !important;
      background-color: transparent !important;
      border-color: transparent !important;
    }
    &:focus {
      border-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  &__form-section-subtitle {
    margin: 5px 0;
    font-weight: 480;
  }
  &__form-input {
    margin: 5px auto;
  }
}

:deep(.modal-content) {
  border: 1px solid $color-border;
  border-radius: 10px;
}
:deep(.modal-footer) {
  border-top: none;
  justify-content: center;
}
:deep(.btn-success:not(:disabled):not(.disabled):active) {
  background-color: rgba($color-secondary-button, 80%);
  border-color: rgba($color-secondary-button, 80%);
  box-shadow: 0 0 0 0.2rem rgba($color-secondary-button, 40%);
}
</style>
