<template>
  <div class="shipping-card" :id="`${titleCard.toLowerCase()}-shipping-card`">
    <article>
      <h3 class="shipping-card__title">{{ titleCard }}</h3>
      <h4 class="shipping-card__subtitle">{{ subtitleCard }} Location</h4>
      <div class="shipping-card__main-location-option">
        <!-- <DropdownLocation
        :titleCard="titleCard"
        :dropdownData="locationsData"
        ></DropdownLocation> -->
        <b-button
          @click="addLocationModal()"
          class="shipping-card__main-location-option--button"
          variant="outline-secondary"
          :id="`${titleCard.toLowerCase()}-shipping-card-button`"
          :style="{ border: '1px solid ' + colorButton }"
        >
          <div>
            <b-icon
              class="location-item shipping-card__main-location-option--plus-icon"
              :icon="locationName === 'Add location' ? 'plus-lg' : 'book-half'"
              aria-hidden="true"
            ></b-icon>
            {{ locationName }}
          </div>
          <b-icon
            class="location-item shipping-card__main-location-option--validate-icon"
            v-if="buttonIcon"
            :icon="buttonIcon"
            :style="{ color: colorButton }"
            scale="1"
          ></b-icon
        ></b-button>
      </div>
      <ul class="shipping-card__main-information">
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="geo-alt"></b-icon>
          {{ deliveryShippingCard.street }} {{ deliveryShippingCard.city }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="person"></b-icon>
          {{ deliveryShippingCard.contactName }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="envelope"></b-icon>
          {{ deliveryShippingCard.contactMail }}
        </li>
        <li class="shipping-card__main-information--text">
          <b-icon class="shipping-card__main-information--icon" icon="telephone"></b-icon>
          {{ deliveryShippingCard.contactPhone }}
        </li>
      </ul>
    </article>
    <article>
      <span id="disabled-wrapper" tabindex="0">
      <b-form-datepicker
        placeholder="Shipping date"
        :id="`datepicker-sm-${titleCard.toLowerCase()}`"
        v-model="deliveryData.expected_date"
        class="mb-2 shipping-card__form-input"
        locale="en"
        disabled
        :state="validateStates.state1"
      ></b-form-datepicker>
      </span>
      <b-tooltip
       v-if="showValidateDay"
       target="disabled-wrapper"
       triggers="hover"
       variant="info"
      >
        Delivery date is estimated based on transit days.<br> LTL Carriers don’t make deliveries on
        <b>Weekends</b> or <b>Holidays</b>.

      </b-tooltip>
      <h6 class="shipping-card__form-time-subtitle">Receiving hours</h6>
      <div class="shipping-card__form-time">
        <el-time-select
          :id="`timepicker-placeholder-${titleCard.toLowerCase()}-first-hour`"
          v-model="hours.firstHourShipping"
          class="shipping-card__form-input shipping-card__form-input--time"
          placeholder="Open"
          :style="{ border: '1px solid ' + validateStates.state2 }"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '23:30',
          }"
        >
        </el-time-select>
        <el-time-select
          :id="`timepicker-placeholder-${titleCard.toLowerCase()}-second-hour`"
          v-model="hours.secondHourShipping"
          class="shipping-card__form-input shipping-card__form-input--time"
          placeholder="Close"
          :style="{ border: '1px solid ' + validateStates.state3 }"
          :picker-options="{
            start: closeTime,
            step: '00:30',
            end: '23:30',
          }"
          :disabled="!hours.firstHourShipping"
        >
        </el-time-select>
      </div>
      <b-form-input
        class="shipping-card__form-input"
        v-model="deliveryData.references"
        placeholder="References required for delivery (SKU, PO, Order Number…)"
        id="input-small"
        type="text"
      ></b-form-input>
      <b-form-textarea
        class="shipping-card__form-input"
        v-model="deliveryData.instructions"
        id="textarea-small"
        size="sm"
        placeholder="Instructions"
      ></b-form-textarea>
    </article>
    <AccountCreationModal
     titleCard="delivery"
     :cityOptions="this.cityOptions"
     @getDeliveryCityByZip="getDeliveryCityByZip"
     ></AccountCreationModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
/* import DropdownLocation from './DropdownLocation.vue'; */
import AccountCreationModal from './AccountCreationModal.vue';
import {
  calculateCloseTime,
} from '../../../mixins/shippingCard/shippingInformation';

export default {
  name: 'DeliveryShippingCard',
  props: {
    titleCard: String,
    subtitleCard: String,
    shipmentInfo: Object,
    checkInfoCards: Boolean,
  },
  components: {
    /* DropdownLocation, */
    AccountCreationModal,
  },
  data() {
    return {
      haulInfo: null,
      locationsData: null,
      locationName: 'Add location',
      deliveryData: {
        shipping_receiving_hours: null,
        expected_date: null,
        references: null,
        instructions: null,
        is_dropoff: true,
        is_pickup: false,
      },
      hours: {
        firstHourShipping: null,
        secondHourShipping: null,
      },
      deliveryShippingCard: {
        contactName: null,
        contactMail: null,
        street: null,
        contactPhone: null,
        city: null,
      },
      infoCheking: false,
      checkSelectedAccount: false,
      checkSelectedContact: false,
      validateStates: {
        state1: null,
        state2: '#d2d7dc',
        state3: '#d2d7dc',
        state4: null,
      },
      colorButton: null,
      buttonIcon: null,
      closeTime: null,
      showValidateDay: false,
      ifWeekendCount: 0,
      cityOptions: null,
      cityByZipCode: null,
    };
  },
  computed: {
    ...mapGetters('salesforceAccounts', ['getAllAccounts']),
    ...mapGetters('salesforceAccounts', ['getDeliverySelectedAccount']),
    ...mapGetters('salesforceContacts', ['getDeliverySelectedContact']),
    ...mapGetters('booking', ['showOriginExpectedDate']),
  },
  watch: {
    getAllAccounts() {
      const options = [...this.getAllAccounts];
      for (let i = 0; i < options.length; i += 1) {
        options[
          i
        ].name_complete = `${options[i].name}, ${options[i].city} ${options[i].state_code} ${options[i].id}`;
      }
      options.unshift({
        label: 'Create a new location',
        name: 'Create a new location',
        name_complete: 'Create a new location',
      });
      this.locationsData = [...options];
    },
    getDeliverySelectedAccount() {
      this.locationName = `${this.getDeliverySelectedAccount.name}, ${this.getDeliverySelectedAccount.postal_code}`;
      this.deliveryShippingCard.street = `${this.getDeliverySelectedAccount.name},`;
      if (this.getDeliverySelectedAccount.id_salesforce) {
        this.checkSelectedAccount = true;
      }
      const accountInfo = {};
      accountInfo.propName = 'consignee_account_id';
      accountInfo.info = this.getDeliverySelectedAccount.id_salesforce;
      this.$store.commit('booking/setSendBooking', accountInfo);
    },
    getDeliverySelectedContact(value) {
      if (this.getDeliverySelectedContact) {
        this.deliveryShippingCard.contactName = `${this.getDeliverySelectedContact.first_name} ${this.getDeliverySelectedContact.last_name}`;
        this.deliveryShippingCard.contactMail = this.getDeliverySelectedContact.email;
        this.deliveryShippingCard.contactPhone = this.getDeliverySelectedContact.phone;
        if (value.id_salesforce) {
          this.checkSelectedContact = true;
        }
        const accountInfo = {};
        accountInfo.propName = 'consignee_contact_id';
        accountInfo.info = value.id_salesforce;
        this.$store.commit('booking/setSendBooking', accountInfo);
      }
    },
    deliveryData: {
      handler(value) {
        const deliveryInfo = {};
        deliveryInfo.propName = 'consignee';
        deliveryInfo.info = { ...value };
        this.$store.commit('booking/setSendBooking', deliveryInfo);
      },
      deep: true,
    },
    hours: {
      handler(value) {
        if (!value.firstHourShipping) {
          this.hours.secondHourShipping = null;
        }
        this.closeTime = calculateCloseTime(this.hours.firstHourShipping);
        if (value.firstHourShipping && value.secondHourShipping) {
          const firstHourFormat = value.firstHourShipping.split(':');
          const secondHourFormat = value.secondHourShipping.split(':');
          this.deliveryData.shipping_receiving_hours = `${firstHourFormat[0]}:${firstHourFormat[1]}-${secondHourFormat[0]}:${secondHourFormat[1]}`;
        }
      },
      deep: true,
    },
    showOriginExpectedDate() {
      if (this.shipmentInfo && this.showOriginExpectedDate) {
        const originExpectedDate = this.showOriginExpectedDate.split('-');
        const month = Number(originExpectedDate[1] - 1);
        const date = new Date(originExpectedDate[0], month, originExpectedDate[2]);
        const daysToAdd = Number(this.shipmentInfo.carrier.transit_time);
        const finalDate = this.getFinalDate(date, daysToAdd);
        this.deliveryData.expected_date = finalDate;
      }
    },
    checkInfoCards() {
      this.infoCheking = this.checkForm();
      this.$emit('validateDeliveryCard', this.infoCheking);
    },
  },
  async created() {
    if (this.shipmentInfo) {
      this.haulInfo = this.shipmentInfo.haul;
      this.completeInfo();
      await this.getInformationByZone();
    }
    this.closeTime = calculateCloseTime('00:00');
  },
  methods: {
    completeInfo() {
      this.deliveryShippingCard.city = this.titleCard === 'Origin'
        ? `${this.cityByZipCode || this.haulInfo.city_from}, ${this.haulInfo.state_from}`
        : `${this.cityByZipCode || this.haulInfo.city_to}, ${this.haulInfo.state_to}`;
    },
    addLocationModal() {
      if (this.titleCard === 'Destination') {
        this.$bvModal.show('delivery-my-location-modal');
      } else {
        this.$bvModal.show(`${this.titleCard.toLowerCase()}-my-location-modal`);
      }
    },
    getFinalDate(date, days) {
      this.ifWeekendCount = 0;
      this.addDays(date, days);
      this.showValidateDay = true;
      this.constructFinalDate(date);
      this.showValidateDay = this.ifWeekendCount > 0;
      return date;
    },
    addDays(date, days) {
      const transitTime = days;
      if (transitTime > 0) {
        date.setDate(date.getDate() + 1);
        this.validateDays(date);
        return this.addDays(date, transitTime - 1);
      }
      if (transitTime === 0) {
        this.validateDays(date);
        return date;
      }
      this.validateDays(date);
      return date;
    },
    validateDays(date) {
      const newDate = date;
      const validateDay = date.getDay();
      if (validateDay === 0) {
        newDate.setDate(date.getDate() + 1);
        this.ifWeekendCount += 1;
      } else if (validateDay === 6) {
        newDate.setDate(date.getDate() + 2);
        this.ifWeekendCount += 1;
      } else {
        return newDate;
      }
      return newDate;
    },
    constructFinalDate(date) {
      const day = date.getUTCDate();
      let month = date.getUTCMonth();
      month = Number(month) + 1;
      const year = date.getFullYear();
      const finalDate = `${year}-${month}-${day}`;
      return finalDate;
    },
    checkForm() {
      if (!this.deliveryData.expected_date) {
        this.validateStates.state1 = false;
      } else {
        this.validateStates.state1 = true;
      }
      if (!this.hours.firstHourShipping) {
        this.validateStates.state2 = '#dc3545';
      } else {
        this.validateStates.state2 = '#28a745';
      }
      if (!this.hours.secondHourShipping) {
        this.validateStates.state3 = '#dc3545';
      } else {
        this.validateStates.state3 = '#28a745';
      }
      if (!this.checkSelectedAccount || !this.checkSelectedContact) {
        this.validateStates.state4 = false;
      } else {
        this.validateStates.state4 = true;
      }
      this.changeStyle();
      if (
        !this.validateStates.state1
        || this.validateStates.state2 === '#dc3545'
        || this.validateStates.state3 === '#dc3545'
        || !this.validateStates.state4
      ) {
        return false;
      }
      return true;
    },
    changeStyle() {
      if (!this.validateStates.state4) {
        this.buttonCheckIcon = false;
        this.buttonIcon = 'x-circle';
        this.colorButton = '#dc3545';
      } else {
        this.buttonCheckIcon = true;
        this.buttonIcon = 'check2-circle';
        this.colorButton = '#28a745';
      }
    },
    async getInformationByZone() {
      const params = {
        zip_code: this.haulInfo.zip_to,
        'api-key': this.shipmentInfo.api_key,
      };
      const citiesByZipResponse = await this.$store.dispatch('informationByZone/getCitiesByZip', params);
      this.cityOptions = citiesByZipResponse?.data;
      params.city = this.haulInfo.city_from;
    },
    getDeliveryCityByZip(value) {
      this.cityByZipCode = value;
      this.completeInfo();
    },
  },
};
</script>

<style scoped lang="scss">
.shipping-card {
  @include shippingCard;
}

:deep(.b-form-btn-label-control.form-control > .btn) {
  color: $color-secondary-button;
}

:deep(.form-control:focus) {
  border-color: $color-secondary-button;
}
:deep(.el-input__inner) {
  border-radius: 10px;
  border: none;
  height: 35px;
  width: 100%;
  color: $color-background-dark;
  font-size: 1rem;
  background-color: $color-white;
  line-height: unset;
  text-align: center;
}

:deep(.el-input__icon) {
  color: $color-secondary-button;
}

@media (max-width: 650px) {
  .shipping-card {
    width: 80%;
  }
}
@media (max-width: 450px) {
  .shipping-card__form-time {
    flex-direction: column;
  }
  .shipping-card__form-input--time {
    width: 100%;
  }
}
</style>
