<template>
  <b-modal
    :id="`${titleCard.toLowerCase()}-my-location-modal`"
    class="account-creation-modal"
    title="Add location and contact"
    :ref="`${titleCard.toLowerCase()}-my-location-modal`"
    ok-disabled
    hide-footer
  >
    <b-form class="account-creation-modal__form" @submit.stop.prevent="onSubmit" action="post">
      <section class="account-creation-modal__form-section">
        <h6 class="account-creation-modal__form-section-subtitle">
          {{ this.titleCard === "Origin" ? "Pickup" : "Delivery" }} Location
        </h6>
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.pickupLocation"
          :placeholder="`${this.titleCard === 'Origin' ? 'Pickup' : 'Delivery'} Location`"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="text"
          @update="checkForm()"
          :state="validateStates['state1']"
          autocomplete="off"
          required
        ></b-form-input>
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.shippingStreet"
          :placeholder="`${this.titleCard === 'Origin' ? 'Shipping' : 'Delivery'} Street`"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="text"
          @update="checkForm()"
          :state="validateStates['state2']"
          autocomplete="off"
          required
        ></b-form-input>
        <span class="text-danger" v-if="form.shippingStreet && form.shippingStreet.length > 30"
          >The address should not exceed 30 characters</span
        >
        <!-- <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.shippingInfo"
          :placeholder="form.shippingInfo"
          id="input-small"
          type="text"
          required
          disabled
        ></b-form-input> -->
        <el-select
            id="select-city-zip"
            class="account-creation-modal__form-input"
            v-if="cityOptions"
            v-model="haulInfoCity"
            @change="selectCity()"
            placeholder="Select City"
            prefix-icon="el-icon-search"
            filterable
          >
          <i slot="prefix" class="el-input__icon el-icon-location-outline"></i>
          <el-option
            v-for="(item, index) in cityOptions"
            :key="index"
            :label="`${item.city}, ${item.state_code}, ${item.zip_code}`"
            :value="item.city">
             {{ item.city}}, {{ item.state_code }}, {{ item.zip_code }}
          </el-option>
        </el-select>
      </section>
      <section class="account-creation-modal__form-section">
        <h6 class="account-creation-modal__form-section-subtitle">
          {{ this.titleCard === "Origin" ? "Shipping" : "Receiving" }} Contact
        </h6>
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.firstName"
          placeholder="First Name"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="text"
          @update="checkForm()"
          :state="validateStates['state3']"
          autocomplete="name"
          required
        ></b-form-input>
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.lastName"
          placeholder="Last Name"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="text"
          @update="checkForm()"
          :state="validateStates['state4']"
          autocomplete="off"
          required
        ></b-form-input>
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.phone"
          placeholder="Phone"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="number"
          :state="validateStates['state5']"
          autocomplete="tel-national"
          @update="validatePhone(form.phone)"
          required
        ></b-form-input>
        <span class="text-danger" v-if="!numberOne || !anyNumber"
          >Please, enter {{ number }} numbers</span
        >
        <b-form-input
          class="account-creation-modal__form-input"
          v-model="form.email"
          placeholder="Email"
          oninvalid="setCustomValidity('Complete this field.')"
          oninput="setCustomValidity('')"
          id="input-small"
          type="email"
          :state="validateStates['state6']"
          autocomplete="email"
          @update="validateEmail(form.email)"
          required
        ></b-form-input>
        <span class="text-danger" v-if="!isEmails">Please, enter an email address.</span>
      </section>
      <b-button
        class="account-creation-modal__form-button"
        type="submit"
        size="sm"
        @click="save()"
        :disabled="busy"
      >
        Save <b-spinner v-if="busy" small></b-spinner>
      </b-button>
    </b-form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { successMessage } from '../../../utils/errorMessages';
import { validateState } from '../../../mixins/shippingCard/shippingInformation';

export default {
  name: 'AccountCreationModal',
  props: {
    titleCard: String,
    cityOptions: Array,
  },
  components: {},
  data() {
    return {
      form: {
        pickupLocation: null,
        shippingStreet: null,
        shippingInfo: null,
        firstName: null,
        lastName: null,
        phone: null,
        email: null,
      },
      haulInfo: null,
      isEmails: true,
      numberOne: true,
      anyNumber: true,
      number: null,
      haulInfoCity: null,
      haulInfoState: null,
      haulInfoCountry: null,
      haulInfoPostalCode: null,
      busy: false,
      validateStates: ['state1', 'state2', 'state3', 'state4', 'state5', 'state6'],
      infoCheking: false,
    };
  },
  computed: {
    ...mapGetters('booking', ['showBookingInfo']),
  },
  watch: {
    showBookingInfo() {
      this.haulInfo = this.showBookingInfo.haul;
      this.infoByTitleCard();
    },
  },
  created() {
    if (this.showBookingInfo) {
      this.haulInfo = this.showBookingInfo.haul;
      this.infoByTitleCard();
    }
  },
  methods: {
    infoByTitleCard() {
      this.form.shippingInfo = this.titleCard === 'Origin'
        ? `${this.haulInfo.city_from}, ${this.haulInfo.state_from} ${this.haulInfo.zip_from}`
        : `${this.haulInfo.city_to}, ${this.haulInfo.state_to} ${this.haulInfo.zip_to}`;
      if (this.titleCard === 'Origin') {
        this.haulInfoCity = this.haulInfo.city_from;
        this.haulInfoState = this.haulInfo.state_from;
        this.haulInfoCountry = this.haulInfo.country_from;
        this.haulInfoPostalCode = this.haulInfo.zip_from;
      } else {
        this.haulInfoCity = this.haulInfo.city_to;
        this.haulInfoState = this.haulInfo.state_to;
        this.haulInfoCountry = this.haulInfo.country_to;
        this.haulInfoPostalCode = this.haulInfo.zip_to;
      }
      this.selectCity();
    },
    async save() {
      this.busy = true;
      this.infoCheking = this.checkForm();
      if (this.infoCheking) {
        const data = {
          body: {
            customer_id: this.haulInfo.customer_id,
            name: this.form.pickupLocation,
            city: this.haulInfoCity,
            state_code: this.haulInfoState,
            country_code: this.haulInfoCountry,
            postal_code: this.haulInfoPostalCode,
            street: this.form.shippingStreet,
          },
          params: {
            'api-key': this.showBookingInfo.api_key,
          },
        };
        const response = await this.$store.dispatch('salesforceAccounts/createAccount', data);
        this.busy = false;
        if (this.infoCheking && response.status === 200) {
          this.busy = true;
          this.setAccount(response);
          const contact = {
            body: {
              customer_id: this.haulInfo.customer_id,
              account_id: response.data.id_salesforce,
              last_name: this.form.lastName,
              first_name: this.form.firstName,
              phone: this.form.phone,
              email: this.form.email,
            },
            params: {
              'api-key': this.showBookingInfo.api_key,
            },
          };
          const contactResponse = await this.$store.dispatch(
            'salesforceContacts/createContact',
            contact,
          );
          this.busy = false;
          if (contactResponse.status === 200) {
            this.setContact(contactResponse);
            this.getAllAccounts();
            successMessage('Updated information.', 1000);
            this.busy = false;
            this.$refs[`${this.titleCard.toLowerCase()}-my-location-modal`].hide();
          }
        }
      }
      this.busy = false;
    },
    onSubmit(event) {
      event.preventDefault();
    },
    async getAllAccounts() {
      const data = {
        customerId: this.haulInfo.customer_id,
        params: {
          'api-key': this.showBookingInfo.api_key,
        },
      };
      await this.$store.dispatch('salesforceAccounts/getAllAccounts', data);
      await this.$store.dispatch('salesforceContacts/getContactbyCustomer', data);
    },
    validateEmail(emails) {
      this.isEmails = this.validateEmailText(emails);
      this.validateStates.state6 = this.isEmails;
    },
    validateEmailText(emails) {
      if (emails !== null && emails !== undefined && emails !== '') {
        if (
          /^.*[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+(?:\.[a-zA-Z0-9-]+)*$/.test(
            emails,
          )
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    validatePhone(phone) {
      if (phone) {
        if (phone.charAt(0) === '1' && (phone.length < 11 || phone.length > 11)) {
          this.numberOne = false;
          this.validateStates.state5 = false;
          this.infoCheking = false;
          this.anyNumber = true;
          this.number = '11';
        } else if (phone.charAt(0) !== '1' && (phone.length < 10 || phone.length > 10)) {
          this.anyNumber = false;
          this.validateStates.state5 = false;
          this.infoCheking = false;
          this.numberOne = true;
          this.number = '10';
        } else {
          this.numberOne = true;
          this.anyNumber = true;
          this.infoCheking = true;
          this.validateStates.state5 = true;
        }
      } else {
        this.anyNumber = false;
        this.validateStates.state5 = false;
        this.infoCheking = false;
      }
    },
    setAccount(response) {
      if (this.titleCard === 'Origin') {
        this.$store.commit('salesforceAccounts/setOriginSelectedAccount', response.data);
      } else {
        this.$store.commit('salesforceAccounts/setDeliverySelectedAccount', response.data);
      }
    },
    setContact(response) {
      if (this.titleCard === 'Origin') {
        this.$store.commit('salesforceContacts/setOriginSelectedContact', response.data);
      } else {
        this.$store.commit('salesforceContacts/setDeliverySelectedContact', response.data);
      }
    },
    checkForm() {
      this.validatePhone(this.form.phone);
      this.validateEmail(this.form.email);
      this.validateColors();
      if (!this.form.pickupLocation
      || !this.form.firstName
      || !this.form.lastName
      || !this.isEmails
      || !this.infoCheking) {
        return false;
      }
      if (!this.form.shippingStreet || this.form.shippingStreet.length > 30) {
        return false;
      }
      return true;
    },
    validateColors() {
      this.validateStates.state1 = validateState(this.form.pickupLocation);
      this.validateStates.state3 = validateState(this.form.firstName);
      this.validateStates.state4 = validateState(this.form.lastName);
      this.validateStates.state5 = validateState(this.infoCheking);
      this.validateStates.state6 = validateState(this.isEmails);
      if (!this.form.shippingStreet || this.form.shippingStreet.length > 30) {
        this.validateStates.state2 = false;
      } else {
        this.validateStates.state2 = true;
      }
    },
    selectCity() {
      const cityInfo = {};
      cityInfo.info = this.haulInfoCity;
      if (this.titleCard === 'Origin') {
        this.$emit('getOriginCityByZip', this.haulInfoCity);
        cityInfo.propName = 'city_from';
        this.$store.commit('booking/setSendBooking', cityInfo);
      } else {
        this.$emit('getDeliveryCityByZip', this.haulInfoCity);
        cityInfo.propName = 'city_to';
        this.$store.commit('booking/setSendBooking', cityInfo);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.account-creation-modal {
  @include modalForm;
}

:deep(.modal-content) {
  border: 1px solid $color-border;
  border-radius: 10px;
}
:deep(.modal-header) {
  color: $color-primary-company;
}
:deep(.modal-title) {
  font-size: 22px;
  font-weight: 600;
}
:deep(.modal-footer) {
  border-top: none;
  justify-content: center;
}

:deep(.el-input__inner) {
  border-radius: 10px;
  height: 35px;
  width: 100%;
  color: $color-background-dark;
  font-size: 1rem;
  line-height: unset;
}

:deep(.el-select .el-input__inner:focus) {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

:deep(.el-input__icon) {
  color: $color-secondary-button;
}

</style>
